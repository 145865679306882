/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@5.62.2/addon/search/matchesonscrollbar.min.css
 * - /npm/codemirror@5.62.2/addon/dialog/dialog.min.css
 * - /npm/codemirror@5.62.2/addon/fold/foldgutter.min.css
 * - /npm/codemirror@5.62.2/theme/eclipse.min.css
 * - /npm/codemirror@5.62.2/lib/codemirror.min.css
 * - /npm/codemirror@5.62.2/theme/monokai.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
